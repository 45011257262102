import React from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    CardText,
} from 'reactstrap';

const CardContent = (props) => {
    const { image, title, description, color } = props


    return (
        <Card className='card-container border-0' 
            style={{
                borderRadius: "20px",
                overflow: "hidden"}}>
            <div className='card-img-container' style={{backgroundColor: color}}>
                <img
                    className="responsive-img"
                    src={image}
                    alt="card_image"
                />
            </div>
            <CardBody className='card-body'>
                <CardTitle className='card-title'>
                    {title}
                </CardTitle>
                <CardText className='p-1'>
                    {description}
                </CardText>
            </CardBody>
        </Card>
    );
};

export default CardContent;