import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { 
    NavItem, 
    NavLink,
    Nav, 
    Navbar
} from "reactstrap";

import logo from "../assets/logo-no-background.png";
import closeImage from '../assets/img/close.png';
import rightArrow from '../assets/img/frame-2.svg';
import TermsandConditions from '../assets/files/Terms_and_Conditions.pdf';
import PrivacyPolicy from '../assets/files/Privacy_Policy.pdf';

const menu_data = [
    {
        name: "About us",
        link: "/about-us",
        type: 'internal'
    },
    {
        name: "Our solutions",
        link: "/solutions",
        type: 'internal'
    },
    {
        name: "Contact us",
        link: "/contact-us",
        type: 'internal'
    },
    {
        name: "Our news",
        link: "/news",
        type: 'internal'
    },
    {
        name: "Terms & Conditions",
        link: TermsandConditions,
        type: 'external'
    },
    {
        name: "Privacy Policy",
        link: PrivacyPolicy,
        type: 'external'
    },
    {
        name: "Complaints",
        link: "https://complaint.healthmoni.com/",
        type: 'external'
    }
];


const Menu = (props) => {
    const { onBurgerMenuClose } = props;
    const [selectedMenu, setSelectedMenu] = useState(null);
    const navigate = useNavigate();
    const { href } = window.location;

    const onLinkClick = (item) => {
        setSelectedMenu(item.link);
        onBurgerMenuClose();
        navigate(item.link);
    };

    const getMenuByHrefLocation = () => {
        if (href) {
            const lastItem = href.substring(href.lastIndexOf("/"));
            if (lastItem === "/") {
                return menu_data[0].link;
            } else {
                const result = menu_data.filter((item) => item.link === lastItem);
                if (result.length > 0) {
                    return result[0].link;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    };

    useEffect(() => {
        const pageName = getMenuByHrefLocation();
        if (pageName) {
            setSelectedMenu(pageName);
        }
    }, [selectedMenu]);

    return (
        <div className="container-center-horizontal">
        <div className="screen">
        <Navbar className="ml-auto" expand="md" style={{ width: "100%", paddingLeft:0 }}>
            {/* <Container> */}
                {/* <div className='menu-nav-container'> */}
                    {/* <div className='menu-logo-container'> */}
                        <img src={logo} className="App-logo" alt="logo" />
                        <img
                            src={closeImage}
                            alt="close_image"
                            width={20}
                            height={20}
                            onClick={onBurgerMenuClose}
                        /> 
                    {/* </div> */}
                    <Nav className="mt-3" navbar style={{ width: "100%" }}>
                        {/* <div className="nav-content" > */}
                            {menu_data.map((item, index) => {
                                return (
                                    <NavItem key={index}>
                                        {item.type === 'internal' ? (
                                            <NavLink className="menu-nav-link-container"  onClick={() => onLinkClick(item)}>
                                                {selectedMenu && selectedMenu === item.link ? <div className='menu-rectangle-purple'></div> : <div className='menu-rectangle-white'></div>}
                                                <div className={index < 3 ? 'bold-text menu-content' : 'normal-text menu-content'}>
                                                    {item.name}
                                                </div>
                                                <div className="img-arrow-container">
                                                    <img className="" /* m-2 img-arrow */
                                                        src={rightArrow}
                                                        alt={"img_arrow" + index}
                                                        width={20}
                                                        height={20}
                                                    />
                                                </div>
                                            </NavLink> ) : (
                                            <NavLink className="menu-nav-link-container" key={index} href={item.link} target="_blank">
                                            {selectedMenu && selectedMenu === item.link ? <div className='menu-rectangle-purple'></div> : <div className='menu-rectangle-white'></div>}
                                            <div className='normal-text menu-content'>
                                                {item.name}
                                            </div>
                                            <div className="img-arrow-container">
                                                <img className="" /* m-2 img-arrow */
                                                    src={rightArrow}
                                                    alt={"img_arrow" + index}
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                        </NavLink>)
                                        }
                                    </NavItem>
                                );
                            })}
                        {/* </div> */}
                    </Nav>
                {/* </div> */}
            {/* </Container> */}
        </Navbar>
        </div>
        </div>
    );
};

export default Menu;
