import React, { useState, useEffect } from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    CardText,
    Row,
    Col
} from 'reactstrap';
import { Media } from "reactstrap";

const SolutionContentMiddle = (props) => {
    const { title, description, contentLink, reverse, isImage, bottomImage, overlapImage } = props
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Row style={{justifyContent:'center', alignItems:'center'}}>
            {width <= 576 || reverse ? (<>
                <Col sm='6'>
                    <Card className='text-container border-0'>
                        <CardBody className='card-text-body'>
                            <CardTitle className='card-title'>
                                {title}
                            </CardTitle>
                            {bottomImage ? (<>
                                <CardText className='p-1'>
                                    {description}
                                    <img src={bottomImage}
                                        alt="media"
                                        className='overlap-image'
                                    />
                                </CardText>
                            </>) :
                                <CardText className='p-1'>
                                    {description}
                                </CardText>}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='6' className='media-container'>
                    <Media className='account-video-img-container'>
                        {isImage ? (
                            <img
                                src={contentLink}
                                alt="media"
                                style={{ maxWidth: '90%', height: 'auto', justifyContent: 'center' }}
                            />
                        ) :
                            <Media right href="#">
                                <iframe id="ytplayer" type="text/html" title="YouTube video" 
                                    // width="100%" height="100%" style={{ border: 0, pointerEvents: "auto" }}
                                    className='media-content1'
                                    src={contentLink} frameborder="0" allowfullscreen="allowfullscreen"
                                    mozallowfullscreen="mozallow fullscreen"
                                    msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen">
                                </iframe>
                            </Media>
                        }
                    </Media>
                </Col> </>) : (<>
                <Col sm='6' className='media-container'>
                    <Media className='account-video-img-container'>
                        {isImage && overlapImage ? (
                            <div className='group-container'>
                                <img
                                    src={contentLink}
                                    alt="media"
                                    style={{ maxWidth: '90%', height: 'auto', justifyContent: 'center' }}
                                />
                                <div className='group-img'>
                                    <div className='overlap-group'>
                                        <img
                                            src={overlapImage}
                                            alt="icon check"
                                            style={{ height: '24px', width: '33px', maxWidth: '90%', justifyContent: 'center' }} /* height: 'auto' */
                                        />
                                    </div>
                                </div>
                            </div>

                        ) : isImage ? (
                            <img
                                src={contentLink}
                                alt="media"
                                style={{ maxWidth: '90%', height: 'auto', justifyContent: 'center' }}
                            />
                        ) :
                            <Media right href="#">
                                <iframe id="ytplayer" type="text/html" title="YouTube video" 
                                    // width="100%" height="100%" style={{ border: 0, pointerEvents: "auto" }}
                                    className='media-content1'
                                    src={contentLink} frameborder="0" allowfullscreen="allowfullscreen"
                                    mozallowfullscreen="mozallow fullscreen"
                                    msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen">
                                </iframe>
                            </Media>
                        }
                    </Media>
                </Col>
                <Col sm='6'>
                    <Card className='text-container border-0'>
                        <CardBody className='card-text-body'>
                            <CardTitle className='card-title'>
                                {title}
                            </CardTitle>
                            {bottomImage ? (<>
                                <CardText className='p-1'>
                                    {description}
                                    <img src={bottomImage}
                                        alt="media"
                                        className='overlap-image'
                                    />
                                </CardText>
                            </>) :
                                <CardText className='p-1'>
                                    {description}
                                </CardText>}
                        </CardBody>
                    </Card>
                </Col> </>)
            }
        </Row>
    );
};

export default SolutionContentMiddle;