import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import ContentTop from '../common/ContentTop';
import homeTopImage from '../assets/img/group-1314@4x.png';
import HomeContentText from '../components/home/HomeContentText';
import CardContent from '../components/home/CardContent';
import { CHAMPAGNE_COLOR, BLACK_HAZE_COLOR, SWANS_DOWN_COLOR, LINK_WATER_COLOR, SHAKESPEARE_COLOR, BURNT_SIENNA_COLOR, ATHENS_GRAY_COLOR } from '../common/Constants';
import cardImage1 from '../assets/img/image-1684022833-4@2x-1.png';
import cardImage2 from '../assets/img/image-215-4@2x-1.png';
import cardImage3 from '../assets/img/image-1684022833-7-1@2x-1.png';
import cardImage4 from '../assets/img/image-269-1@2x.png';
import cardImage5 from '../assets/img/image-219@2x-1.png';
import cardImage6 from '../assets/img/image-1317@2x-1.png';
import HomeFactContent from '../components/home/HomeFactContent';
import line1 from '../assets/img/line-691-4.png';
import HomeFactOrient from '../components/home/HomeFactOrient';
import ImageTextContent from '../components/home/ImageTextContent';
import arrow from '../assets/img/arrow-9-1.svg';
import line from '../assets/img/line-688-1.png';
import appImage1 from '../assets/img/group-1294@3x.png';
import TeamContent from '../components/home/TeamContent';
import teamImage1 from '../assets/img/ellipse-1148@2x.png';
import teamImage2 from '../assets/img/ellipse-1149@2x.png';
import teamImage3 from '../assets/img/ellipse-1152@2x.png';
import teamImage4 from '../assets/img/ellipse-1151@2x.png';
import teamImage5 from '../assets/img/ellipse-1150@2x.png';
import FooterBar from '../common/Footer';
import FooterImage1 from '../assets/img/hm-logo-white-rgb-7@2x.png';
import FooterImage2 from '../assets/img/image-224@2x.png';
import vline from '../assets/img/line-634.svg';

const Home = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const data = [
        {
            title: 'Single-app solution for financial management',
            description: 'Managing social care billing across multiple platforms can be difficult. HealthMoni provides an all-in-one app solution that simplifies billing and payments, saves time and makes it easy to track transactions.',
            source: 'https://www.healthmoni.com/our-solutions.html',
            contentLink: 'https://www.youtube.com/embed/q9T-kP8iyBo?rel=0',
            isImage: false
        },
        {
            title: 'In-app communications for quick access support',
            description: 'Getting support can be time consuming if you don’t know who can help. HealthMoni provides in-app contact support to quickly connect care recipients with local authorities or home care service providers.',
            source: 'https://www.healthmoni.com/our-solutions.html',
            contentLink:'https://youtube.com/embed/CIAdFL918Ew?rel=0',
            isImage: false
        },
        {
            title: 'Simplified administration saving time and effort',
            description: 'Keeping records be demanding and overwhelming,  with information spread across emails, letters and receipts. HealthMoni users can receive and store invoices, timesheets and receipts in the app.',
            source: 'https://www.healthmoni.com/our-solutions.html',
            contentLink: appImage1,
            isImage: true
        },
      ];

    return (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <ContentTop title={'Digital financial tools '}
                subtitle={'to support independant living'}
                description={'Our mission at HealthMoni is to make it easier for people to manage health and social care finances for themselves or others.'}
                image={homeTopImage} />
            <HomeContentText title={'Increased demand for home care'}
                description={'The UK is facing an increased demand for care at home. As a result more financial management responsibilities are falling to those who need care, or those supporting them. Our aim is to reduce this burden using digital solutions.'} />
            {width <= 1024 ? <>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='5'>
                        <CardContent image={cardImage1}
                            title={'People need social care'}
                            description={'13.7 million people in the UK require long-term social care. 2.7 million of them receive financial support from local authorities with the costs.'}
                            color={CHAMPAGNE_COLOR} />
                    </Col>
                    <Col sm='5'>
                        <CardContent image={cardImage2}
                            title={'Our population is ageing'}
                            description={'People are living longer and the demand for social care is increasing. Because of this, requests for support from local authorities is growing.'}
                            color={BLACK_HAZE_COLOR} />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='5'>
                        <CardContent image={cardImage3}
                            title={'People want care at home'}
                            description={'Older people and people with long-term conditions would rather receive care at home than move into a care home.'}
                            color={SWANS_DOWN_COLOR} />

                    </Col>
                    <Col sm='5'>
                        <CardContent image={cardImage4}
                            title={'Finding support can be confusing'}
                            description={'People who need help don’t always know how to access it and support entitlements can be complex and difficult to understand.'}
                            color={LINK_WATER_COLOR} />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='5'>
                        <CardContent image={cardImage5}
                            title={'Managing finances can be a struggle'}
                            description={'People receiving local authority support to pay for their care can find the financial administration involved complicated and time consuming.'}
                            color={SHAKESPEARE_COLOR} />

                    </Col>
                    <Col sm='5'>
                        <CardContent image={cardImage6}
                            title={'Digital tools can help but are under-used'}
                            description={'Technology can make things simpler and easier to do, including managing aspects of our own healthcare. It has the potential to make similar impact in social care.'}
                            color={BURNT_SIENNA_COLOR} />
                    </Col>
                </Row></> :
                <><Row style={{ justifyContent: "center" }}>
                    <Col sm='4'>
                        <CardContent image={cardImage1}
                            title={'People need social care'}
                            description={'13.7 million people in the UK require long-term social care. 2.7 million of them receive financial support from local authorities with the costs.'}
                            color={CHAMPAGNE_COLOR} />

                    </Col>
                    <Col sm='4'>
                        <CardContent image={cardImage2}
                            title={'Our population is ageing'}
                            description={'People are living longer and the demand for social care is increasing. Because of this, requests for support from local authorities is growing.'}
                            color={BLACK_HAZE_COLOR} />
                    </Col>
                    <Col sm='4'>
                        <CardContent image={cardImage3}
                            title={'People want care at home'}
                            description={'Older people and people with long-term conditions would rather receive care at home than move into a care home.'}
                            color={SWANS_DOWN_COLOR} />

                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='4'>
                        <CardContent image={cardImage4}
                            title={'Finding support can be confusing'}
                            description={'People who need help don’t always know how to access it and support entitlements can be complex and difficult to understand.'}
                            color={LINK_WATER_COLOR} />
                    </Col>
                    <Col sm='4'>
                        <CardContent image={cardImage5}
                            title={'Managing finances can be a struggle'}
                            description={'People receiving local authority support to pay for their care can find the financial administration involved complicated and time consuming.'}
                            color={SHAKESPEARE_COLOR} />

                    </Col>
                    <Col sm='4'>
                        <CardContent image={cardImage6}
                            title={'Digital tools can help but are under-used'}
                            description={'Technology can make things simpler and easier to do, including managing aspects of our own healthcare. It has the potential to make similar impact in social care.'}
                            color={BURNT_SIENNA_COLOR} />
                    </Col>
                </Row></>}
            {width <= 1024 && width > 576 ? <>
                <Row className='fact-container' style={{ marginTop: '2em' }}>
                    <Col sm='4'>
                        <HomeFactContent title={'13.7 million'}
                            description={'people in the UK required long-term social care support in 2022'}
                            source={'Source: NHS'} />
                    </Col>
                    <Col sm='4' style={{ width: '2px', height: '41px' }}>
                        <img
                            className='img-line'
                            src={line1}
                            alt='seperator line'
                        />
                    </Col>
                    <Col sm='4'>
                        <HomeFactContent title={'18.6%'}
                            description={'of over 65\'s in the UK required social care support in 2021 compared to 16.4% in 2011'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                </Row>
                <Row className='fact-container'>
                    <Col sm='4'>
                        <HomeFactContent title={'2 million'}
                            description={'people received formalised care in the UK in 2021'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                    <Col sm='4' style={{ width: '2px', height: '41px' }}>
                        <img
                            // className='img-line'
                            src={line1}
                            alt='seperator line'

                        />
                    </Col>
                    <Col sm='4'>
                        <HomeFactContent title={'£10.5 billion'}
                            description={'was the UK market value of paid home-care in 2021'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                </Row>
                <Row className='fact-container'>
                    <Col sm='4'>
                        <HomeFactContent title={'£5.5 billion'}
                            description={'is managed by recipients of direct payments or self funders'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                    <Col sm='4' style={{ width: '2px', height: '41px' }}>
                        <img
                            // className='img-line'
                            src={line1}
                            alt='seperator line'

                        />
                    </Col>
                    <Col sm='4'>
                        <HomeFactContent title={'Over 814,000'}
                            description={'UK adults received homecare support in 2020'}
                            source={'Source: Office for National Statistics'} />
                    </Col>
                </Row>
            </> : width <= 576 ? <>
                <Row className='fact-container' style={{ marginTop: '2em' }}>
                    <Col sm='2'>
                        <HomeFactContent title={'13.7 million'}
                            description={'people in the UK required long-term social care support in 2022'}
                            source={'Source: NHS'} />
                    </Col>
                    <Col sm='2'  style={{width:'90px', height:'24px'}}>
                        <img 
                            // className='img-line'
                            src={line}
                            alt='seperator line'
                        />
                    </Col>
                    <Col sm='2'>
                        <HomeFactContent title={'18.6%'}
                            description={'of over 65\'s in the UK required social care support in 2021 compared to 16.4% in 2011'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                    <Col sm='2' style={{width:'90px', height:'24px'}}>
                        <img 
                            // className='img-line'
                            src={line}
                            alt='seperator line'

                        />
                    </Col>
                    <Col sm='2'>
                        <HomeFactContent title={'2 million'}
                            description={'people received formalised care in the UK in 2021'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                    <Col sm='2'  style={{width:'90px', height:'24px'}}>
                        <img 
                            // className='img-line'
                            src={line}
                            alt='seperator line'
                        />
                    </Col>
                    <Col sm='2'>
                        <HomeFactContent title={'£10.5 billion'}
                            description={'was the UK market value of paid home-care in 2021'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                    <Col sm='2' style={{width:'90px', height:'24px'}}>
                        <img 
                            // className='img-line'
                            src={line}
                            alt='seperator line'

                        />
                    </Col>
                    <Col sm='2'>
                        <HomeFactContent title={'£5.5 billion'}
                            description={'is managed by recipients of direct payments or self funders'}
                            source={'Source: The King’s Fund'} />
                    </Col>
                    <Col sm='2' style={{width:'90px', height:'24px'}}>
                        <img 
                            // className='img-line'
                            src={line}
                            alt='seperator line'

                        />
                    </Col>
                    <Col sm='2'>
                        <HomeFactContent title={'Over 814,000'}
                            description={'UK adults received homecare support in 2020'}
                            source={'Source: Office for National Statistics'} />
                    </Col>
                </Row> </>
                : <>
                    <Row className='fact-container' style={{ marginTop: '2em' }}>
                        <Col sm='3'>
                            <HomeFactContent title={'13.7 million'}
                                description={'people in the UK required long-term social care support in 2022'}
                                source={'Source: NHS'} />
                        </Col>
                        <Col sm='3' style={{ width: '2px', height: '41px' }}>
                            <img
                                className='img-line'
                                src={line1}
                                alt='seperator line'
                            />
                        </Col>

                        <Col sm='3'>
                            <HomeFactContent title={'18.6%'}
                                description={'of over 65\'s in the UK required social care support in 2021 compared to 16.4% in 2011'}
                                source={'Source: The King’s Fund'} />
                        </Col>
                        <Col sm='3' style={{ width: '2px', height: '41px' }}>
                            <img
                                // className='img-line'
                                src={line1}
                                alt='seperator line'

                            />
                        </Col>
                        <Col sm='3'>
                            <HomeFactContent title={'2 million'}
                                description={'people received formalised care in the UK in 2021'}
                                source={'Source: The King’s Fund'} />
                        </Col>
                    </Row>
                    <Row className='fact-container'>
                        <Col sm='3'>
                            <HomeFactContent title={'£10.5 billion'}
                                description={'was the UK market value of paid home-care in 2021'}
                                source={'Source: The King’s Fund'} />
                        </Col>
                        <Col sm='3' style={{ width: '2px', height: '41px' }}>
                            <img
                                // className='img-line'
                                src={line1}
                                alt='seperator line'

                            />
                        </Col>
                        <Col sm='3'>
                            <HomeFactContent title={'£5.5 billion'}
                                description={'is managed by recipients of direct payments or self funders'}
                                source={'Source: The King’s Fund'} />
                        </Col>
                        <Col sm='3' style={{ width: '2px', height: '41px' }}>
                            <img
                                // className='img-line'
                                src={line1}
                                alt='seperator line'

                            />
                        </Col>
                        <Col sm='3'>
                            <HomeFactContent title={'Over 814,000'}
                                description={'UK adults received homecare support in 2020'}
                                source={'Source: Office for National Statistics'} />
                        </Col>
                    </Row> </>}
            <Row className='fact-container-orient'>
                <HomeFactOrient 
                    title={'We need to shift away from reliance on residential care and offer people genuine options for drawing on outstanding care at home and in the community.'}
                    description={'The Department of Health and Social Care'}/>
            </Row>
            <HomeContentText title={'Co-designed solutions'}
                description={'We worked in partnership with people receiving Local Authority financial support to fund their social care and their carers. They helped us understand the problems of managing these finances and together, we have co-designed the solutions. When it comes to managing social care finances, our user-friendly, person-centred technology tackles common problems and works for direct payment recipients, self-funders, and home care service providers.'} />
            {data.map((item, index) => (
                <ImageTextContent key={index} 
                    title={item.title}
                    description={item.description}
                    source={item.source}
                    arrow= {arrow}
                    // line= {line}
                    contentLink={item.contentLink}
                    isImage={item.isImage}
                    reverse={index % 2 !== 0}
                />))}
            <Row className='fact-container'>
                <HomeContentText title={'Meet our team'}
                    description={'We’re a group of fintech experts, passionate about using our skills to help improve health and social care systems in the UK.'} />
                {width <= 1024 ? <>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='5'>
                        <TeamContent image={teamImage1}
                            title={'Howard Allen'}
                            description={'Chief Executive Officer'}
                            color={ATHENS_GRAY_COLOR} />
                    </Col>
                    <Col sm='5'>
                        <TeamContent image={teamImage2}
                            title={'Amitava Deb'}
                            description={'Chief Technology Officer'}
                            color={ATHENS_GRAY_COLOR} />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='5'>
                        <TeamContent image={teamImage3}
                            title={'Ghufran Khan'}
                            description={'Lead Frontend Developer'}
                            color={ATHENS_GRAY_COLOR} />

                    </Col>
                    <Col sm='5'>
                        <TeamContent image={teamImage4}
                            title={'Huma Perveen'}
                            description={'Data Analyst'}
                            color={ATHENS_GRAY_COLOR} />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='5'>
                        <TeamContent image={teamImage5}
                            title={'Natalia Tulkina'}
                            description={'Finance Manager'}
                            color={ATHENS_GRAY_COLOR} />

                    </Col>
                </Row></> :
                <><Row style={{ justifyContent: "center" }}>
                    <Col sm='4'>
                        <TeamContent image={teamImage1}
                            title={'Howard Allen'}
                            description={'Chief Executive Officer'}
                            color={ATHENS_GRAY_COLOR} />

                    </Col>
                    <Col sm='4'>
                        <TeamContent image={teamImage2}
                            title={'Amitava Deb'}
                            description={'Chief Technology Officer'}
                            color={ATHENS_GRAY_COLOR} />
                    </Col>
                    <Col sm='4'>
                        <TeamContent image={teamImage3}
                            title={'Ghufran Khan'}
                            description={'Lead Frontend Developer'}
                            color={ATHENS_GRAY_COLOR} />

                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col sm='4'>
                        <TeamContent image={teamImage4}
                            title={'Huma Perveen'}
                            description={'Data Analyst'}
                            color={ATHENS_GRAY_COLOR} />
                    </Col>
                    <Col sm='4'>
                        <TeamContent image={teamImage5}
                            title={'Natalia Tulkina'}
                            description={'Finance Manager'}
                            color={ATHENS_GRAY_COLOR} />

                    </Col>
                </Row></>}
            </Row>
            <Row className='footer-container'>
                <FooterBar image1={FooterImage1}
                    image2={FooterImage2}
                    vline={vline}/>
            </Row>  
        </Container>
    )
}

export default Home;