import React from 'react';
import { Container } from 'reactstrap';

const HomeFactOrient = (props) => {
    const { title, description } = props

    return (
        <Container fluid>
            <div className='quote-container'>
                <blockquote style={{margin:0}}>
                    {title}
                </blockquote>
            </div>
            <p className='p-1 text-center' style={{ color: "#ffffff" }}>
                {description}
            </p>
        </Container>
    );
};

export default HomeFactOrient;