import React from 'react';
import {
    Container,
    Row,
} from 'reactstrap';
import ContentTop from '../common/ContentTop';
import solutionTopImage from '../assets/img/shutterstock-2280036487-2.png';
import SolutionContentTop from '../components/solutions/SolutionContentTop';
import checkImage from '../assets/img/vector-13@2x.png';
import SolutionContentMiddle from '../components/solutions/SolutionContentMiddle';
import accountImage from '../assets/img/group-1207-6@2x.png';
import accountImage1 from '../assets/img/group-1297@3x.png';
import accountImage2 from '../assets/img/group-1221@2x.png';
import { FANTASY_COLOR, MERINO_COLOR, AQUA_HAZE_COLOR, PINK_PEARL_COLOR } from '../common/Constants';
import requestImage from '../assets/img/group-1208-3@2x.png';
import requestImage1 from '../assets/img/group-1298@3x.png';
import requestImage2 from '../assets/img/request_bottom.png';
import SolutionContentAdminTop from '../components/solutions/SolutionContentAdminTop';
import SolutionContentAdminMiddle from '../components/solutions/SolutionContentAdminMiddle';
import adminImage from '../assets/img/group-1109-4@2x.png';
import adminImage1 from '../assets/img/group-1212-3-1@2x.png';
import adminImage2 from '../assets/img/group-1299@3x.png';
import SolutionContentBottom from '../components/solutions/SolutionContentBottom';
import bottomImage from '../assets/img/group-323@3x.png';
import FooterBar from '../common/Footer';
import FooterImage1 from '../assets/img/hm-logo-white-rgb-7@2x.png';
import FooterImage2 from '../assets/img/image-224@2x.png';
import vline from '../assets/img/line-634.svg';



const Solutions = () => {
    const accountOptions = [
        {
            text: 'Receive payment requests with invoices or time sheets'
        },
        {
            text: 'Make payments and bank transfers'
        },
        {
            text: 'Transactions recorded with supporting documents'
        },
        {
            text: 'Communication platform with local authority or care service provider'
        }
    ]
    const account_data = [
        {
            title: 'Receive bills and make payments using our app',
            description: 'We’ve simplified the payment process so there’s no need to spend time looking through paper bills and emails. Payment requests are received in the app together with the documents supporting them, like invoices and time sheets. All that’s left for the user to do is review and pay!',
            contentLink: accountImage1,
            isImage: true
        },
        {
            title: 'Stay on top of record keeping',
            description: 'Record keeping can be time consuming and daunting. Managing your finances is quicker and easier using HealthMoni. Your receipts and invoices are all in one place and it\'s easy to download if needed.',
            contentLink: 'https://youtube.com/embed/JdTJxumWpl0?rel=0',
            isImage: false
        },
        {
            title: 'Support that isn’t an uphill struggle',
            description: 'HealthMoni users can get help quickly whenever they need it. Our app features access to payment and technical support and makes it easier to communicate with local authorities about direct payments.',
            contentLink: accountImage2,
            isImage: true
        },
    ];

    const requestOptions = [
        {
            text: 'Make payment requests quickly'
        },
        {
            text: 'Share invoices, timesheets or expense receipts with payment requests'
        },
        {
            text: 'Easily track payment requests'
        }
    ]

    const request_data = [
        {
            title: 'Payment requests made easy',
            description: 'HealthMoni Request takes the hassle out of raising payment requests. Quickly and easily send requests with supporting documentation including invoices and timesheets.',
            contentLink: 'https://www.youtube.com/embed/8PaFb86lGHc?rel=0',
            isImage: false,
            bottomImage: requestImage2
        },
        {
            title: 'Track payment requests',
            description: 'Stay up-to-date and on top of payment requests using the ap to see what\'s been paid, declined or is outstanding.',
            contentLink: requestImage1,
            isImage: true
        }
    ];

    const adminOptions = [
        {
            text: 'App onboarding monitoring'
        },
        {
            text: 'Payment expenditure data to identify under or over spending'
        },
        {
            text: 'Download reconciliation documents for auditing activities'
        },
        {
            text: 'Categorise payments'
        },
        {
            text: 'Communication features for reaching direct payment beneficiaries'
        }
    ]

    const admin_data = [
        {
            title: 'Communicate quickly, easily and clearly',
            description: 'The Communications platform provides a place for Personal Budget Direct Payment recipients to raise queries with the Local Authority. Providing a simple solution to manage Direct Payment queries.',
            contentLink: adminImage1,
            // isImage: true
        },
        {
            title: 'Data and information to improve processes',
            description: 'Dashboards provide data visualisations and detailed information to support management and reconciliation of direct payments.',
            contentLink: adminImage2,
            // isImage: true,
            // overlapImage: overlapImage1
        }
    ]


    return (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <ContentTop title={'Our solutions '}
                subtitle={'for care receivers, care givers and local authorities.'}
                description={'HealthMoni brings everyone involved onto a single online platform. Using three digital solutions, we’ve made it easy for people receiving and providing care  and the local authorities supporting them manage the finances together.'}
                image={solutionTopImage} />
            <SolutionContentTop title={'HealthMoni '}
                subtitle={'Account'}
                description={'Our digital bank account for care receivers, or those appointed to manage their budget and pay for care.'}
                options={accountOptions}
                checkImage={checkImage}
                bgcolor={FANTASY_COLOR}
                accountImage={accountImage} />
            {account_data.map((item, index) => (
                <SolutionContentMiddle key={index}
                    title={item.title}
                    description={item.description}
                    contentLink={item.contentLink}
                    isImage={item.isImage}
                    reverse={index % 2 !== 0}
                />))}
            <SolutionContentTop title={'HealthMoni '}
                subtitle={'Request'}
                description={'Our payment request app for social care workers, so they can share and track payment requests for work completed.'}
                options = {requestOptions}
                checkImage={checkImage}
                bgcolor={MERINO_COLOR}
                accountImage={requestImage} />
            {request_data.map((item, index) => (
                <SolutionContentMiddle key={index}
                    title={item.title}
                    description={item.description}
                    contentLink={item.contentLink}
                    isImage={item.isImage}
                    reverse={index % 2 !== 0}
                    bottomImage={item.bottomImage}
                />))}
            <SolutionContentAdminTop title={'HealthMoni '}
                subtitle={'Admin'}
                description={'Our administration dashboard for local authorities and care service providers, so they can monitor payment and reconciliation data and communicate efficiently.'}
                options={adminOptions}
                checkImage={checkImage}
                bgcolor={AQUA_HAZE_COLOR}
                accountImage={adminImage} />
            {admin_data.map((item, index) => (
                <SolutionContentAdminMiddle key={index}
                    title={item.title}
                    description={item.description}
                    contentLink={item.contentLink}
                    reverse={index % 2 !== 0}
                    // overlapImage= {item.overlapImage}
                />))}
            <SolutionContentBottom title={'Keeping users safe'}
                description={'Online security is embedded across all our platforms. HealthMoni is end to end encrypted and provides bank-grade security to shield users’ accounts and ensuring they and their money remain safe. Account holders’ money is safeguarded and held with the Bank of England.'}
                bgcolor={PINK_PEARL_COLOR}
                contentImage={bottomImage} />
            <Row className='footer-container'>
                <FooterBar image1={FooterImage1}
                    image2={FooterImage2}
                    vline={vline}/>
            </Row>  
        </Container>
    );
}

export default Solutions;