import React from 'react';
import {
    Container,
    Row,
} from 'reactstrap';
import ContentTop from '../common/ContentTop';
import newsTopImage from '../assets/img/shutterstock-2067591212-2.png';
import NewsCard from '../components/news/NewsCard'; 
import {BLACK_SQUEEZE_COLOR} from '../common/Constants';
import FooterBar from '../common/Footer';
import FooterImage1 from '../assets/img/hm-logo-white-rgb-7@2x.png';
import FooterImage2 from '../assets/img/image-224@2x.png';
import vline from '../assets/img/line-634.svg';
import bottomImage from '../assets/img/image-204.png';

const News = () => {
    const news_data = [
        {
            title: 'Can fintech help social care?',
            description: 'We partnered with the University of Manchester to find out the ways fintech could help the social care sector and the 2 million people relying on financial support from local authorities.',
            bgcolor: BLACK_SQUEEZE_COLOR,
            source: 'https://www.healthmoni.com/Positioning_Paper.pdf',
            linkText: 'read more + ',
            image: bottomImage,
        },
    ]
    return (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <ContentTop title={'Our news '} 
                subtitle={'updates, announcements, reports and media.'} 
                description={'Our mission to make it easier for people  managing social care for themselves or others is just beginning. For media enquiries please contact '}
                descriptionLinkText={'support@healthmoni.com'}
                descriptionLink={'mailto:support@healthmoni.com'}
                image={newsTopImage} />
            {news_data.map((item, index) => (
                <NewsCard key={index}
                title={item.title}
                description={item.description}
                bgcolor = {item.bgcolor}
                source = {item.source}
                cardImage = {item.cardImage}
                linkText = {item.linkText}
                image = {item.image}
                />
            ))}
            <Row className='footer-container' style={{marginTop: '4em'}}>
                <FooterBar image1={FooterImage1}
                    image2={FooterImage2}
                    vline={vline}/>
            </Row>  

        </Container>
    );
};

export default News;