import React, { useState, useEffect } from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    CardText,
    Row,
    Col
} from 'reactstrap';
import { Media } from "reactstrap";

const SolutionContentAdminMiddle = (props) => {
    const { title, description, contentLink, reverse, overlapImage } = props
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Row style={{justifyContent:'center', alignItems:'center'}}>
            {width > 576 && reverse ? (<>
                <Col sm='6'>
                    <Media className='account-video-img-container'>
                        {overlapImage ? (
                                <div className='group-container'>
                                    <img
                                        src={contentLink}
                                        alt="media"
                                        style={{ maxWidth: '90%', height: 'auto', justifyContent: 'center' }}
                                    />
                                    <div className='group-img'>
                                        <div className='overlap-group2-1'>
                                            <img
                                                src={overlapImage}
                                                alt="icon check"
                                                style={{ height: '24px', width: '33px'}}
                                            />
                                        </div>
                                    </div>
                                </div>

                            ) : 
                        <img
                            src={contentLink}
                            alt="media"
                            style={{ maxWidth: '90%', height: 'auto', justifyContent: 'center' }}
                        />
                        }
                    </Media>
                </Col>
                <Col sm='6'>
                    <Card className='text-container border-0'>
                        <CardBody className='card-text-body'>
                            <CardTitle className='card-title'>
                                {title}
                            </CardTitle>
                            <CardText className='p-1'>
                                {description}
                            </CardText>
                        </CardBody>
                    </Card>
                </Col> </>) : (<>
                    <Col sm='6'>
                        <Card className='text-container border-0'>
                            <CardBody className='card-text-body'>
                                <CardTitle className='card-title'>
                                    {title}
                                </CardTitle>
                                <CardText className='p-1'>
                                    {description}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm='6'>
                        <Media className='account-video-img-container'>
                            {overlapImage ? (
                                <div className='group-container'>
                                    <img
                                        src={contentLink}
                                        alt="media"
                                        style={{ maxWidth: '90%', height: 'auto', justifyContent: 'center' }}
                                    />
                                    <div className='group-img'>
                                        <div className='overlap-group'>
                                            <img
                                                src={overlapImage}
                                                alt="icon check"
                                                style={{ height: '24px', width: '33px'}}
                                            />
                                        </div>
                                    </div>
                                </div>

                            ) : 
                                <img
                                    src={contentLink}
                                    alt="media"
                                    style={{ maxWidth: '90%', height: 'auto', justifyContent: 'center' }}
                                />
                            }
                        </Media>
                    </Col> </>)
            }
        </Row>
    );
};

export default SolutionContentAdminMiddle;