import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    CardLink,
} from 'reactstrap';

const NewsCard = (props) => {
    const { title, description, source, bgcolor, linkText, image } = props
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Container fluid className='news-container p-0'> {/* style={{ width: '80%', marginTop: '4em' }} */}
            {width <=1024 ? ( <>
            <Row className='news-card-container' style={{ backgroundColor: bgcolor }}>
                <Card className='contact-card-text-container border-0 p-0' >
                    <CardBody className='contact-card-body p-0'>
                        <div className='news-overlap-group'>
                            <div className='news-posted'>Posted: 24 March 2023</div>
                        </div>
                        <CardTitle className='contact-card-title'>
                            {title}
                        </CardTitle>
                        <CardText className='p-1'>
                            {description}
                        </CardText>
                        <CardLink className='contact-card-link' href={source} target="_blank">
                            {linkText}
                        </CardLink>
                    </CardBody>
                </Card>
            </Row>
            <img
                className="responsive-img"
                src={image}
                alt="card_image"
                // width={160}
                // height={64}
            /> </>) : (<>
            <Row className='news-card-container' style={{ backgroundColor: bgcolor }}>
                <Col sm ='5' className='p-0'>
                <Card className='contact-card-text-container border-0 p-0' >
                    <CardBody className='contact-card-body p-0'>
                        <div className='news-overlap-group'>
                            <div className='news-posted'>Posted: 24 March 2023</div>
                        </div>
                        <CardTitle className='contact-card-title'>
                            {title}
                        </CardTitle>
                        <CardText className='p-1'>
                            {description}
                        </CardText>
                        <CardLink className='contact-card-link' href={source} target="_blank">
                            {linkText}
                        </CardLink>
                    </CardBody>
                </Card>
                </Col>
                <Col sm ='7' className='p-0'>
                    <div className='news-image-container'>
                        <img
                        className="news-image"
                        src={image}
                        alt="card_image"
                        />
                    </div>
                </Col>
            </Row> 
            </>)}
        </Container>

    );
};

export default NewsCard;