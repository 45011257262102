import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import Solutions from "../pages/Solutions";
import News from "../pages/News";

const Contents = (props) => {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<Home />} />
            <Route exact path="/solutions" element={<Solutions />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/news" element={<News />} />
        </Routes>
    )
}

export default Contents;