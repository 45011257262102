import React, { useState, useEffect } from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    CardText,
    CardLink,
    Row,
    Col
} from 'reactstrap';
import { Media } from "reactstrap";

const ImageTextContent = (props) => {
    const { title, description, source, contentLink, arrow, reverse, isImage } = props
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Row>
            {width > 576 && reverse ? (<>
                <Col sm='6' className='media-container'>
                    <Media className='card-video-img-container'>
                        {isImage ? (
                            <img
                            src={contentLink}
                            alt="media"
                            style={{ Width: '90%', height: 'auto' }}
                            />
                        ) :
                        <Media right href="#">
                            <iframe id="ytplayer" type="text/html" title="YouTube video" 
                                className='media-content'
                                src={contentLink} frameborder="0" allowfullscreen="allowfullscreen"
                                mozallowfullscreen="mozallow fullscreen"
                                msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen">
                            </iframe>
                         </Media>
                        }
                    </Media>
                </Col>
                <Col sm='6'>
                    <Card className='card-text-container border-0'>
                        <CardBody className='card-text-body'>
                            <CardTitle className='card-title'>
                                {title}
                            </CardTitle>
                            <CardText className='p-1'>
                                {description}
                            </CardText>
                            <CardLink className='card-link' href={source} target="_blank">
                                Learn more
                                <img className='arrow-1' src={arrow} alt="arrow" />
                            </CardLink>
                        </CardBody>
                    </Card>
                </Col> </>) :
            (<> <Col sm='6'>
                    <Card className='card-text-container border-0'>
                        <CardBody className='card-text-body'>
                            <CardTitle className='card-title'>
                                {title}
                            </CardTitle>
                            <CardText className='p-1'>
                                {description}
                            </CardText>
                            <CardLink className='card-link' href={source} target="_blank">
                                Learn more
                                <img className='arrow-1' src={arrow} alt="arrow" />
                            </CardLink>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='6' className='media-container'>
                    <Media className='card-video-img-container'>
                        {isImage ? (
                            <img
                            src={contentLink}
                            alt="media"
                            style={{ maxWidth: '90%', height: 'auto' }}
                            />
                        ) :
                         <Media right href="#">
                            <iframe  id="ytplayer" type="text/html" title="YouTube video" 
                                className='media-content'
                                src={contentLink} frameborder="0" allowfullscreen="allowfullscreen"
                                mozallowfullscreen="mozallow fullscreen"
                                msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen">
                            </iframe>
                         </Media>
                        }
                    </Media>
                </Col> </>) 
            }
        </Row>
    );
};

export default ImageTextContent;