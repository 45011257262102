import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardText,
} from 'reactstrap';

const SolutionContentTop = (props) => {
    const { title, subtitle, description, checkImage, options, bgcolor, accountImage } = props;
    return (
        <Container fluid style={{ width: "100%" }}>
            <Row className='account-container' style={{ backgroundColor: bgcolor }}>
                <Col sm='6' className='p-0'>
                    <Card className='account-text-container border-0 p-0'>
                        <CardBody className='account-text-body'>
                            <div className='account-title-container'>
                                <CardTitle className='account-title'>
                                    {title}
                                </CardTitle>
                                <CardTitle className='account-subtitle'>
                                    {subtitle}
                                </CardTitle>
                            </div>
                            <CardText className='p-1'>
                                {description}
                            </CardText>
                            {options && options.map((item, index) => {
                                return (
                                    <Row key={index}>
                                        <Col sm='1' style={{width: '1em'}}>
                                            <img className='check-1' src={checkImage} alt="check" />
                                        </Col>
                                        <Col>
                                            <CardText className='p-1'>
                                                {item.text}
                                            </CardText>
                                        </Col>
                                    </Row>)
                            })}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='6' className='d-flex justify-content-center p-2'>
                    <img
                        src={accountImage}
                        alt="account_image"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default SolutionContentTop;