import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Navbar,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import logo from "../assets/logo-no-background.png";
import burgerMenu from '../assets/img/group-1340@2x.png';
// import Cookies from "js-cookie";

const menuData = [
    {
        name: "About us",
        link: "/about-us",
    },
    {
        name: "Our Solutions",
        link: "/solutions",
    },
    {
        name: "Contact us",
        link: "/contact-us",
    },
];

const Header = (props) => {
    const {onBurgerMenuClick} = props;
    const [selectedMenu, setSelectedMenu] = useState(null);
    const navigate = useNavigate();
    const { href } = window.location;
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onLinkClick = (item) => {
        setSelectedMenu(item.link);
        navigate(item.link);

    };

    const getMenuByHrefLocation = () => {
        if (href) {
            const lastItem = href.substring(href.lastIndexOf("/"));
            if (lastItem === "/") {
                return menuData[0].link;
            } else {
                const result = menuData.filter((item) => item.link === lastItem);
                if (result.length > 0) {
                    return result[0].link;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    };

    useEffect(() => {
        const pageName = getMenuByHrefLocation();
        if (pageName) {
            setSelectedMenu(pageName);
        }
    }, [selectedMenu]);

    const onMenuMobile = () => {
        onBurgerMenuClick(true);
      }

    return (
        <Navbar className="ml-auto" expand="md" style={{ width: "100%" }}>
            {/* <Container> */}
                {/* <div className='nav-container'> */}
                    {/* <div className='logo-container'> */}
                        <img src={logo} className="App-logo" alt="logo" />
                    {/* </div> */}
                    {width <= 576 ? <img
                            src={burgerMenu}
                            alt="HealthMoni"
                            width={25}
                            height={25}
                            onClick={onMenuMobile}
                        /> :
                    <Nav className="d-flex justify-content-end" navbar style={{ width: "100%" }}>
                        {/* <div className='nav-container'>
                        <div className='logo-container'>
                            <img src={logo} className="App-logo" alt="logo" />
                        </div> */}
                        
                        <div className="nav-content">
                            {menuData.map((item, index) => {
                                return (
                                    <NavItem key={index}>
                                        <NavLink className='nav-link-clickable' key={index} onClick={() => onLinkClick(item)}>
                                            {selectedMenu && selectedMenu === item.link ? <div className='rectangle-purple'></div> : <div className='rectangle-white'></div>}
                                            <div className="about-us-1 quicksand-bold-ship-gray-18px">
                                                {item.name}
                                            </div>
                                        </NavLink>
                                    </NavItem>)
                            })}
                        </div>
                    </Nav>
                    }
                    
                {/* </div> */}
            {/* </Container> */}
        </Navbar>
    );
};

export default Header;