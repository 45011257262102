import React from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    CardText,
} from 'reactstrap';

const HomeFactContent = (props) => {
    const { title, description, source } = props


    return (
        <Card className='fact-card-container border-0'>
            <CardBody className='card-body'>
                <CardTitle className='fact-title'>
                    {title}
                </CardTitle>
                <CardText className='p-card1'>
                    {description}
                </CardText>
                <CardText className='p-card2'>
                    {source}
                </CardText>
            </CardBody>
        </Card>
    );
};

export default HomeFactContent;