import React from 'react';
import {
    Container,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardText,
    CardLink,
} from 'reactstrap';

const ContactUsCard = (props) => {
    const { title, description, source, cardImage, bgcolor, linkText, descriptionLink } = props
    return (
        <Container fluid className='contact-container'>
        <Row className='contact-card-container' style={{backgroundColor: bgcolor}}> 
            <Card className='contact-card-text-container border-0' >   
                <div className='contact-card-img-container' >
                    <img
                        // className="responsive-img"
                        src={cardImage}
                        alt="card_image"
                        // style={{backgroundColor: bgcolor}}
                    />
                </div>
                <CardBody className='contact-card-body'>
                    <CardTitle className='contact-card-title'>
                        {title}
                    </CardTitle>
                    <CardText className='p-1'>
                        {description}
                        {descriptionLink && (<>
                      {'HealthMoni is currently funded by Innovate UK and Founder investment. Our paper - '}
                        <a href={descriptionLink} className="underline-link" target='_blank' rel="noopener noreferrer">Can fintech help Social Care?</a>
                        {' underpins our ambition to develop a new type of financial institution, become a leading ESG and EDI business and attain B Corporation status. We’re interested in talking to institutional investors, especially those with experience in public/private enterprise and technology with a social purpose.'}
                    </> )}
                    </CardText>
                    <CardLink className='contact-card-link' href={source} target="_blank">
                        {linkText}
                    </CardLink>
                </CardBody>
            </Card>
        </Row>
    </Container>

    );
};

export default ContactUsCard;