import React, { useState, useEffect } from "react";
import './App.css';
import './styleguide.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import Header from "../src/common/Header";
import Contents from './components/Contents';
import Menu from './pages/Menu';

function App() {
  const [showMobileMenu, setShowmobileMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const onBurgerMenuClick = (state) => {
    setShowmobileMenu(state)
  }

  const onBurgerMenuClose = () => {
    setShowmobileMenu(false);
  }

  if (showMobileMenu && width <= 576) {
    return <Menu onBurgerMenuClose={onBurgerMenuClose} />
  }

  if (showMobileMenu && width > 576) {
    setShowmobileMenu(false);
  }
  return (
        <div className="container-center-horizontal">
          <div className="screen">
              {showMobileMenu && <Menu />}
              {!showMobileMenu && <><Header onBurgerMenuClick={onBurgerMenuClick} />
                <Contents /></>}
          </div>
        </div>
  );
}

export default App;
