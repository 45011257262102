export const SHIP_GRAY_COLOR = '#3a3847';
export const PURPLE_MOUNTAIN_MAJESTRY_COLOR = '#9c85a8';
export const CHAMPAGNE_COLOR = '#f9ebc8';
export const BLACK_HAZE_COLOR = '#f7f7f7';
export const SWANS_DOWN_COLOR = '#dde4eb';
export const LINK_WATER_COLOR = '#d4edf4';
export const SHAKESPEARE_COLOR = '#52b3d9';
export const BURNT_SIENNA_COLOR = '#e87d6b';
export const ATHENS_GRAY_COLOR = '#eeeef2';
export const FANTASY_COLOR = '#faf3f4';
export const MERINO_COLOR = '#eff4ea';
export const AQUA_HAZE_COLOR = '#EBF3F5';
export const PINK_PEARL_COLOR = '#f5f1f9';
export const BLACK_SQUEEZE_COLOR = '#f3f8f9';
export const ALTO_COLOR = '#d9d9d933';
