import React from 'react';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardText,
} from 'reactstrap';

const SolutionContentBottom = (props) => {
    const { title, description, bgcolor, contentImage } = props;

    return (
        // <Container fluid style={{ width: "100%" }}>
            <Row className='bottom-container' style={{ backgroundColor: bgcolor }}>
                <Col sm='6' className='p-0'>
                    <Card className='account-text-container border-0 p-0'>
                        <CardBody className='card-text-body'>
                            <CardTitle className='card-title'>
                                {title}
                            </CardTitle>
                            <CardText className='p-1'>
                                {description}
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='6' className='d-flex justify-content-center p-2'>
                    <img
                        src={contentImage}
                        alt="content_image"
                        style={{ width: '50%', maxWidth: '100%', height: 'auto' }}
                    />
                </Col>
            </Row>
        // </Container>
    );
};

export default SolutionContentBottom;