import React, { useState, useEffect } from 'react';
import {
    Container,
    NavbarText,
    Row,
    Col,
} from 'reactstrap';
import {SHIP_GRAY_COLOR, PURPLE_MOUNTAIN_MAJESTRY_COLOR} from '../common/Constants';


const ContentTop = (props) => {
    const {title, subtitle, description, descriptionLink, descriptionLinkText, image} = props
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return(
        <Container fluid className='mt-4'>
            {width <= 1024 ? ( <>
            <div className='title-container'>
                <NavbarText className='title' style={{color: SHIP_GRAY_COLOR}}>   
                    {title}
                </NavbarText>
                <NavbarText className='title' style={{color: PURPLE_MOUNTAIN_MAJESTRY_COLOR }} >
                    {subtitle}
                </NavbarText>
            </div>
            <div className='subtitle-container'>
                <p className='text-justify'>
                    {description}
                    {descriptionLink && (<>
                      {' '}
                        <a href={descriptionLink} className="underline-link">{descriptionLinkText}</a>
                    </> )}
                </p>
            </div> </> ) : (
                <Row style={{justifyContent:'center', alignItems:'center'}}>
                    <Col sm='6'>
                        <div className='title-container'>
                            <NavbarText className='title' style={{color: SHIP_GRAY_COLOR}}>   
                                {title}
                            </NavbarText>
                            <NavbarText className='title' style={{color: PURPLE_MOUNTAIN_MAJESTRY_COLOR }} >
                                {subtitle}
                            </NavbarText>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className='subtitle-container'>
                            <p className='text-justify'>
                                {description}
                                {descriptionLink && (<>
                                {' '}
                                    <a href={descriptionLink} className="underline-link">{descriptionLinkText}</a>
                                </> )}
                            </p>
                        </div>
                    </Col>
                </Row>

            )}
            <div className='top-img-container'>
                <img
                    className="responsive-img"
                    src={image}
                    alt="top_image"
                />
            </div>

        </Container>
    );
};

export default ContentTop;
